
$strokeColor: rgb(169, 169, 169);
$heartColor: #000000;
$size: 165px; // change this to manipulate overall size
$totalAnim: 7s;
$delay: 0.1s;
$squareLen: 240;
$circleLen: 188.522;
$heartLen: 308.522;
$svgSize: 90px;
$circleW: 60px;

.heart-loader {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  margin-top: calc($size / -2);
  width: $size;
  height: $size;
  overflow: visible;
  animation: heart-grow-pulse 3s linear 5.5s infinite;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    width: 180px;
    height: 180px;
  }

  &__group {
    transform-origin: 0 $svgSize;
    animation: group-anim $totalAnim $delay 1;
    animation-fill-mode: forwards;
  }

  &__square {
    stroke: $strokeColor;
    stroke-dasharray: $squareLen, $squareLen;
    stroke-dashoffset: $squareLen;
    animation: square-anim $totalAnim $delay 1;
  }

  &__circle {
    stroke: $strokeColor;
    stroke-dasharray: $circleLen, $circleLen;
    stroke-dashoffset: $circleLen;
    transform-origin: $circleW calc($circleW / 2);

    &.m--left {
      animation: left-circle-anim $totalAnim $delay 1;
    }

    &.m--right {
      animation: right-circle-anim $totalAnim $delay 1;
    }
  }

  &__heartPath {
    stroke: $heartColor;
    fill: transparent;
    stroke-dasharray: $heartLen, $heartLen;
    stroke-dashoffset: $heartLen;
    animation: heart-anim $totalAnim $delay 1;
    animation-fill-mode: forwards;
  }
}
@keyframes square-anim {
  12% {
    stroke-dashoffset: 0;
  }
  43% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  85% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
}
@keyframes left-circle-anim {
  12% {
    stroke-dashoffset: $circleLen;
  }
  31% {
    stroke-dashoffset: 0;
    transform: translateY(0);
  }
  41% {
    stroke-dashoffset: 0;
    transform: translateY(calc($circleW / -2));
  }
  43% {
    stroke-dashoffset: 0;
    transform: translateY(calc($circleW / -2));
    opacity: 1;
  }
  85% {
    stroke-dashoffset: 0;
    transform: translateY(calc($circleW / -2));
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    transform: translateY(calc($circleW / -2));
    opacity: 0;
  }
}
@keyframes right-circle-anim {
  12% {
    stroke-dashoffset: $circleLen;
  }
  31% {
    stroke-dashoffset: 0;
    transform: translateX(0);
  }
  41% {
    stroke-dashoffset: 0;
    transform: translateX(calc($circleW / 2));
  }
  43% {
    stroke-dashoffset: 0;
    transform: translateX(calc($circleW / 2));
    opacity: 1;
  }
  85% {
    stroke-dashoffset: 0;
    transform: translateX(calc($circleW / 2));
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    transform: translateX(calc($circleW / 2));
    opacity: 0;
  }
}
@keyframes group-anim {
  from {
    transform: rotate(-45deg);
  }
  54% {
    transform: rotate(-45deg);
  }
  90% {
    transform: rotate(-45deg);
    opacity: 1;
  }
  97% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(-45deg);
  }
}
@keyframes heart-anim {
  55% {
    stroke-dashoffset: $heartLen;
    fill: transparent;
  }
  70% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  87% {
    stroke-dashoffset: 0;
    fill: $heartColor;
    filter: drop-shadow(0 0 10px rgb(164, 163, 163));
  }
  95% {
    stroke-dashoffset: 0;
    fill: $heartColor;
    filter: drop-shadow(0 0 20px rgb(238, 233, 233));
  }
  100% {
    stroke-dashoffset: 0;
    fill: $heartColor;
    filter: drop-shadow(0 0 30px rgb(238, 233, 233));
  }
}

@keyframes heart-grow-pulse {
  from {
    filter: drop-shadow(0 0 40px rgb(238, 233, 233));
  }
  50% {
    filter: drop-shadow(0 0 25px rgb(237, 78, 78));
  }
  to {
    filter: drop-shadow(0 0 40px rgb(238, 233, 233));
  }
}

.other {
  position: absolute;
  left: 0;
  bottom: 0.5rem;
  width: 100%;
  text-align: right;

  &__link {
    font-size: 1.3rem;
    margin: 0 1rem;
  }
}