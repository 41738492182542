$base: 62.5%;
$root: 1.6rem;

$radius: 0.5rem;

$cassette: #231f23;
$label: linear-gradient(#faedda 50%, #f8312f 50%);
$spool: #fff;
$spool-border: #8b8681;
$wire: #45230c;
$body: #4c8077;

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap");

$lato: "Lato", sans-serif;

.container_cas {
  margin: 0 auto;
  max-width: 60rem;
  width: 100%;
  display: block;
  position: relative;
  transform: scale(0.75);
}

.cassette {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 25rem;
  height: 15rem;
  margin-top: 7.5rem;
  z-index: 2;
  border-radius: $radius;
  background: $cassette;
  color: #231f23;
  box-shadow: 0 12px 25px 8px rgba(255,255,255,0.4);

  &:before,
  &:after {
    content: "";
    position: absolute;
    background: $spool-border;
    color: $cassette;
    border-radius: 100%;
    width: 0.5rem;
    height: 0.5rem;
    left: 0.5rem;
  }

  &:before {
    top: 0.5rem;
  }

  &:after {
    bottom: 0.5rem;
  }
}

.cassette__label {
  height: 65%;
  position: relative;
  width: auto;
  background: $label;
  margin: 1.5rem;
  left: 0;
  border-radius: $radius;

  &:before,
  &:after {
    content: "";
    position: absolute;
    background: $spool-border;
    color: $cassette;
    border-radius: 100%;
    width: 0.5rem;
    height: 0.5rem;
    right: -1rem;
  }

  &:before {
    top: -1rem;
  }

  &:after {
    bottom: -3.2rem;
  }
}

.cassette__details {
  display: flex;
  align-items: flex-start;
  margin: 1rem;
}

.cassette__side {
  text-transform: uppercase;
  font-size: 2.2rem;
  margin-top: 0.5rem;
}

.cassette__info {
  width: 85%;
  margin-left: auto;
}

.cassette__lines {
  height: 0.1rem;
  background: lighten($cassette, 50%);
  margin-top: 2rem;
  position: relative;

  &:before,
  &:after {
    content: "";
    height: 0.1rem;
    width: 100%;
    background: lighten($cassette, 50%);
    position: absolute;
  }

  &:before {
    top: -1rem;
  }

  &:after {
    top: 1rem;
  }
}

.cassette__block {
  display: flex;
  flex-direction: column;
}

.cassette__spool {
  width: 85%;
  background: $cassette;
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  border-radius: $radius;
  margin-top: 1.8rem;
}

.cassette__circle {
  border-radius: 100%;
  background: $spool-border;
  width: 2.8rem;
  height: 2.8rem;
  position: relative;

  &:before {
    content: "";
    border-radius: 100%;
    background: $spool;
    width: 2.2rem;
    height: 2.2rem;
    position: absolute;
    left: 0.3rem;
    top: 0.3rem;
  }
}

.cassette__wire {
  background: $spool;
  width: 4rem;
  border-radius: 0.1rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 2;

  &:before,
  &:after {
    content: "";
    height: 100%;
    width: 10%;
  }

  &:before {
    background: $wire;
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
    width: 25%;
  }

  &:after {
    background: $spool-border;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
  }
}

.cassette__mechanics {
  width: 60%;
  border: 0.1rem solid $spool-border;
  height: 3rem;
  border-bottom: none;
  margin: 0 auto;
  position: relative;
  bottom: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

.cassette__tuning {
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  background: $spool;
  position: relative;
  margin-bottom: -0.5rem;

  &:before {
    content: "";
    width: 0.6rem;
    height: 0.5rem;
    background: $spool;
    position: absolute;
    left: 2rem;
    border-radius: 0.1rem;
  }

  &:nth-child(2) {
    transform: rotate(180deg);

    &:before {
      bottom: 0;
    }
  }
}
