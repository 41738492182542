@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sassy+Frass&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Redacted+Script:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Redacted&display=swap');

@layer base {
    body {
        @apply bg-neutral-800 text-zinc-300;
    }
}

@layer components {
    .control-btn {
        @apply text-neutral-900 cursor-pointer hover:text-yellow-400 transition-all w-1/4 py-3 text-3xl bg-gradient-to-t from-indigo-600 to-pink-600 rounded active:text-neutral-200 active:translate-y-2 active:shadow-md active:shadow-white shadow-lg shadow-black;
    }
}